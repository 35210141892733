import React, { useEffect, useState } from 'react';
import {
    Select, Popover,
    Col, Tree
} from 'antd';
import { apiClient } from 'helper/request/api_client';
import _, { get } from 'lodash';
import { useQuery } from 'helper/hook/get_query';

export const FilterMachine = React.memo(({ initFilter, onChange = () => { } }) => {

    const [dateEnterprise, setDataEnterprise] = useState(null);
    const [filter, setFilter] = useState({ area: '', machine: '', line: '', process: '' })
    const [dataShow, setDataShow] = useState('');
    const query = useQuery();
    useEffect(() => {
        _requestData();
    }, []);

    useEffect(() => {
        try {
            if (!dateEnterprise) return () => { };
            if (query.get('machine_id') || query.get('line') || query.get('area') || query.get('process')) {
                setTimeout(() => {
                    setFilter({
                        machine: query.get('machine_id'),
                        process: query.get('process'),
                        line: query.get('line'),
                        area: query.get('area'),
                    })

                }, 1000)
            } else if (!_.isEmpty(initFilter)) {
                setFilter(initFilter);
            } else {
                const listAreaIds = Object.keys(dateEnterprise)
                const lines = get(dateEnterprise, `[${listAreaIds[0]}].lineMap`);
                const linesId = Object.keys(lines)
                const processes = get(dateEnterprise, `[${lines[linesId[0]].process[0]}].processMap`);
                const processesId = Object.keys(processes)
                console.log("----------linesId-----------", listAreaIds);
                console.log("----------linesId-----------", lines);
                console.log("----------linesId-----------", linesId);
                console.log("----------processesId-----------", processesId);
                // console.log("------------process---------", Object.keys(process));
                // const machine = lines[linesId[0]].machines
                const machine = lines[linesId[0]].process[0].machines
                setFilter({
                    machine: ('' + machine[2]?.id) || ('' + machine[0].id),
                    process: lines[linesId[0]].process[0],
                    line: linesId[0],
                    area: listAreaIds[0],
                })
            }
        } catch (err) {
            console.log('errors set default machine filter', err)
        }
    }, [initFilter, dateEnterprise, query])

    useEffect(() => {
        onChange(filter)
    }, [filter]);
    const [treeData, setTreeData] = useState([]);
    const _requestData = async () => {
        const { data } = await apiClient.get('/enterprise/detail')
        const dataConvert = {};
        const treeDataConvert = [];
        let f = true;
        const saveMachine_id = localStorage.getItem('machine_id');

        data.areas.map((area, aIndex) => {
            const lineChildren = area.lines.map(line => {
                const processChildren = line.process.map(process => {
                    const machinesChildren = process.machines.map((machine, mIndex) => {
                        if (saveMachine_id) {
                            if (saveMachine_id == machine.id) {
                                setDataShow({
                                    machine: `${machine.name}`,
                                    area: `${area.name}`,
                                    line: `${line.name}`,
                                    process: `${process.name}`,
                                })
                                setFilter({
                                    area: area.id,
                                    line: line.id,
                                    process: process.id,
                                    machine: machine.id,
                                })
                            }
                        } else {
                            if (f) {
                                f = false;
                                setDataShow({
                                    machine: `${machine.name}`,
                                    area: `${area.name}`,
                                    line: `${line.name}`,
                                    process: `${process.name}`,
                                })
                                setFilter({
                                    area: area.id,
                                    line: line.id,
                                    process: process.id,
                                    machine: machine.id,
                                })
                            }
                        }
                        return {
                            title: `${machine.name} | (MACHINE)`,
                            key: `${area.id}#${line.id}#${process.id}#${machine.id}`,
                            name: `${machine.name}`,
                            areas: `${area.name}`,
                            line: `${line.name}`,
                            process: `${process.name}`,
                        }
                    })
                    return {
                        title: `${process.name} | (PROCESS)`,
                        key: `${area.id}#${line.id}#${process.id}`,
                        disabled: true,
                        children: machinesChildren
                    }
                })
                return {
                    title: `${line.name} | (LINE)`,
                    key: `${area.id}#${line.id}`,
                    disabled: true,
                    children: processChildren
                }
            })
            treeDataConvert.push({
                title: `${area.name} | (AREA)`,
                key: `${area.id}`,
                disabled: true,
                children: lineChildren
            })

        })
        setTreeData(treeDataConvert);

        data.areas.map((area) => {
            dataConvert[area.id] = area;
            dataConvert[area.id].lineMap = {};
            area.lines.map((line) => {
                dataConvert[area.id].lineMap[line.id] = line
                dataConvert[area.id].lineMap[line.id].processMap = {};
                line.process.map((process) => {
                    dataConvert[area.id].lineMap[line.id].processMap[process.id] = process
                })
            })
        });
        console.log("----------dataConvert-----------", dataConvert);
        setDataEnterprise(dataConvert)
    }
    // 
    const [selectedKeys, setSelectedKeys] = useState([]);

    const onSelect = (selectedKeysValue, info) => {
        try {

            if (!selectedKeysValue) return
            setSelectedKeys(selectedKeysValue);
            const [area, line, process, machine] = selectedKeysValue[0]?.split('#');
            console.log({ area, line, process, machine })
            localStorage.setItem('machine_id', machine);
            setDataShow({
                machine: info.node.name,
                area: info.node.areas,
                line: info.node.line,
                process: info.node.process,
            })
            setFilter({
                area,
                line,
                process,
                machine,
            })
        } catch (e) {
        }
    };

    return (
        <Col xs={20} sm={20} md={20} lg={20} xl={11} xxl={12}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                {/*  */}
                <TitleForm text="MACHINE" />
                <Popover content={<Tree
                    onSelect={onSelect}
                    treeData={treeData}
                    selectedKeys={selectedKeys}
                    defaultExpandAll
                />} title="Chọn máy">
                    <div style={{
                        ...selectStyle, border: '1px solid #ddd', paddingLeft: 5,
                        cursor: 'pointer'
                    }}>{dataShow?.machine || "Chọn máy"}</div>
                </Popover>
                <TitleForm text={`PROCESS`} />
                <div style={selectStyle}>{dataShow?.process || ""}</div>
                <TitleForm text="LINE" />
                <div style={selectStyle}>{dataShow?.line || ""}</div>
                <TitleForm text="AREA" />
                <div style={selectStyle}>{dataShow?.area || ""}</div>
                {/*  */}
                {/* <TitleForm text="Area" />
                <Select size="small" style={selectStyle} value={filter.area} onChange={(val) => {
                    setFilter({ area: val, line: '', machine: '' })
                }}>
                    {dateEnterprise && Object.keys(dateEnterprise)
                        .map(areaId => <Select.Option key={areaId} value={areaId}>{get(dateEnterprise, `${areaId}.name`, '')}</Select.Option>)}
                </Select>
                <TitleForm text="Line" />
                <Select size="small" style={selectStyle} value={filter.line} onChange={(val) => {
                    setFilter({ ...filter, line: val, machine: '' })
                }}>
                    {get(dateEnterprise, `${filter.area}.lineMap`)
                        && Object.keys(get(dateEnterprise, `${filter.area}.lineMap`, []))
                            .map(lineID => <Select.Option key={lineID} value={lineID}>
                                {get(dateEnterprise, `${filter.area}.lineMap.${lineID}.name`, '')}
                            </Select.Option>)}
                </Select>
                <TitleForm text="Process" />
                <Select size="small" style={selectStyle} value={filter.process} onChange={(val) => {
                    setFilter({ ...filter, line: val, machine: '' })
                }}>
                    {get(dateEnterprise, `${filter.process}.processMap`)
                        && Object.keys(get(dateEnterprise, `${filter.process}.processMap`, []))
                            .map(processID => <Select.Option key={processID} value={processID}>
                                {get(dateEnterprise, `${filter.process}.processMap.${processID}.name`, '')}
                            </Select.Option>)}
                </Select>
                <TitleForm text="Machine" />
                <Select size="small" style={selectStyle} value={filter.machine}
                    onChange={(val) => { setFilter({ ...filter, machine: val }) }} >
                    {get(dateEnterprise, `${filter.area}.lineMap.${filter.line}.process[0]`)
                        && get(dateEnterprise, `${filter.area}.lineMap.${filter.line}.machines`, [])
                            .map(machine => <Select.Option key={machine.id} value={machine.id + ''}>
                                {machine.name}
                            </Select.Option>)}
                </Select> */}
            </div>
        </Col>
    )
})

const TitleForm = ({ text }) => <div style={{ padding: '0px 10px', background: '#fff', borderRadius: 5, border: '1px solid #ddd' }}>
    <span style={{ color: '#1890ff', fontWeight: '600', textTransform: 'uppercase' }}>{text}</span>
</div>

const selectStyle = {
    width: 120, borderRadius: 5, marginLeft: 4, marginRight: 12,
    cursor: 'not-allowed'
}